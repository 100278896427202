<template>
  <v-card class="p-0 pb-1 mt-0 mb-4" :key="refresh">
    <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
      <v-container fluid class="m-0 p-0">
        <v-row dense align="center" justify="start" class="h26  m-0 p-0">
          <v-col cols="auto" class="m-0 p-0 ps-2">
            <v-avatar dark :color="hexTints(area.cor,0.8)" size="20" class="m-0 p-0">
              <i v-i:duo#note#16 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
            </v-avatar>
          </v-col>
          <v-col cols="auto" class="m-0 p-0 ps-2">
            <div class="mt-0 line-height-1 f-lato fw-600 fs-8pt text-truncate">
              <span>Conteúdos associados ao tópico gerador</span>
              <span class="ms-1 round border pe-1 fs-10pt" :style="'background-color:'+hexTints(area.cor,0.87)">
                {{qtde}}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <conteudoitem @rightClick="rightClick" v-for="(conteudo,key,index) in conteudos" :key="key" :moveRight="true" :area="area" :uc="uc" :item="conteudo" />

  </v-card>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "conteudosassociados",
    components: {
      'conteudoitem': () => import('./conteudo-item.vue'),
    },
    props: {
      area: { default: null, type: Object },
      uc: { default: null, type: Object },
      topico: { default: null, type: Object },
    },
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
        conteudos: {},
        qtde: 0,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      self.build();
    },
    methods:{

      build() {
        var self = this;
        rdb.ref('/curriculo/topicos/topicos/'+this.topico.id+'/conteudos').on('value',function(snapshot) {
          self.conteudos = {};
          self.qtde = 0;
          var elem = snapshot.val();
          if(elem != null) {
            self.conteudos = elem;
            self.qtde = Object.keys(self.conteudos).length;
            console.log("self.conteudos",self.conteudos);
          }
          self.refresh++;
        });
      },

      rightClick(conteudo) {
        console.log("rightClick",conteudo);
        rdb.ref('/curriculo/topicos/topicos/'+this.topico.id+'/conteudos/'+conteudo.id).set(null);
        rdb.ref('/curriculo/conteudos/conteudos/'+conteudo.id+'/topicos/'+this.topico.id).set(null);
      }
    }
  }
</script>

<style scoped>
</style>
